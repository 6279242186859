$sputum1: #e3e6d0;
$sputum2: #e0e6ae;
$sputum3: #c3cf60;
$sputum4: #a8b83b;
$sputum5: #8f9a1d;

.form-control {
  font-size: 1rem;
}
.standard-form {
  label {
  }
  fieldset legend {
    color: $secondary;
    font-size: 1rem;
    text-align: center;
  }
  .form-group {
    border-radius: $border-radius;

    padding: 0.75rem;
    position: relative;
    hr {
      border-top-color: $white;
    }
    .num {
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      background-color: $dark;
      color: $white;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      left: 0.75rem;
    }
    .instructions {
      font-size: 0.75rem;
    }
  }
}
.standard-form-nums {
  .form-group {
    padding: 0.75rem 0.75rem 0.75rem 2.75rem;
  }
}

.required-info {
  margin: 0rem 0rem 1rem 0rem;
  font-size: 0.75rem;
}
.required {
  color: $danger;
}

.from-control-micro {
  display: inline-block;
  width: 3.75rem;
  font-size: 1.5rem;
  line-height: 1rem;
  text-align: right;
}
.micro-addon {
  font-size: 1.5rem;
  margin-left: 1rem;
}

.from-control-xl-micro {
  display: inline-block;
  width: 7.5rem;
  font-size: 2.25rem;
}
.xl-micro-addon {
  font-size: 1.5rem;
  margin-left: 0.5rem;
}
// specials
.checkbox-container {
  border-top: 0.0625rem solid $white;
  label {
    position: relative;
    padding: 1rem 0 1rem 3rem;
    margin-bottom: 0;
    .icon-wrap {
      position: absolute;
      margin-left: -2.5rem;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
  .checkbox {
    border-bottom: 0.0625rem solid $white;
    transition: 0.3s;
    &:hover {
      background-color: $white;
    }
  }
  input[type="checkbox"] {
    opacity: 0;
    width: 0rem;
    position: absolute;

    + label {
      display: block;
    }
    &:checked {
      + label {
        // color: $white;
        // background: $primary;
      }
    }
    &:focus {
      + label {
        box-shadow: 0 0rem 0rem rgb(0, 0, 0, 0);
        background-color: $dark;
        color: $white;
      }
    }
  }
}

.form-recharge {
  .form-group {
    background-color: $primary-accent;
    border-radius: $border-radius-lg;
    padding: 1rem;
    margin-bottom: 1.25rem;
    label {
      text-transform: uppercase;
      font-family: $headings-font-family;
      margin-bottom: 0.5rem;
    }
    .form-control {
      border-radius: 0.75rem;
      border-color: $white;
      font-family: $headings-font-family;
      font-size: 1.063rem;
      padding: 0.75rem 1rem;
    }
  }
  .divider {
    background-color: $white;
    height: 0.125rem;
    opacity: 1;
  }
}

.sub-label {
  font-size: 0.625rem;
  color: $dark;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 0;
}

.scale-labels {
  font-size: 0.75rem;
  color: #666;;
}

// difficulty

$difficulty1: #156e00;
$difficulty2: #229317;
$difficulty3: #f0a143;
$difficulty4: #f04343;
$difficulty5: #b40000;

$difficulty-colors: (
  1: $difficulty1,
  2: $difficulty2,
  3: $difficulty3,
  4: $difficulty4,
  5: $difficulty5,
);

@each $color, $value in $difficulty-colors {
  .text-difficulty-#{$color} {
    color: rgba($value, 1) !important;
  }
  .text-wellbeing-#{$color} {
    color: rgba($value, 1) !important;
  }
  .bg-symptom-#{$color} {
    background-color: rgba($value, 1) !important;
  }
}

legend {
  text-align: center;
  font-size: 1rem;
}
.radio-group {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .form-check {
    padding: 0;
  }
  .form-check-inline {
    margin: 0;
    .form-check-label {
      color: $dark;
      border: 0.125rem solid $white;
      border-radius: 0.625rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  input[type="radio"] {
    opacity: 0;
    width: 0rem;
    + label {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer;
      background-color: $white;
      font-size: 1rem;
      width: 3.125rem;
      height: 3.125rem;
      border-radius: $border-radius;
      color: $dark;
    }

    &:hover {
      + label {
        background-color: rgb(6, 94, 184, 0.25);
        color: $black;
      }
    }

    &:checked {
      + label {
        color: $white;
        background: $primary;
      }
      &:hover {
        + label {
          background-color: $primary;
        }
      }
    }
    &:focus {
      + label {
        box-shadow: 0 0rem 0.5rem $primary;
      }
    }
  }
}

.radio-group-text {
  .form-check-inline .form-check-label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    span {
      font-weight: normal;
      font-size: 0.625rem;
      display: block;
      color: #999;
      line-height: 0.625rem;
    }
  }

  input[type="radio"]:checked:hover + label {
    background-color: #123b1d;
    border:none;
    span {
      color:$white;
    }
  }

  input[type="radio"]:checked + label {
    color: #fff;
    background: #123b1d;
    border:none;
    span {
      color:$white;
    }
  }
}
.radio-group-text.chosen{

  .form-check-inline .form-check-label {
   color:#ccc;
    span {
      color: #ccc;
    }
  }

  input[type="radio"]:checked:hover + label {
    background-color: #123b1d;
    border:none;
    span {
      color:$white;
    }
  }

  input[type="radio"]:checked + label {
    color: #fff;
    background: #123b1d;
    border:none;
    span {
      color:$white;
    }
  }
}


.input-difficulty .radio-group {
  .form-check-inline {
    .form-check-label {
      font-size: 2rem;
      color: $white;
    }
  }

  .form-check-inline:nth-child(1) {
    input[type="radio"] {
      + label {
        background-color: $difficulty1;
      }
      &:hover {
        + label {
          background-color: rgb(242, 226, 78, 0.25);
        }
      }
      &:checked {
        + label {
          background-color: $difficulty1;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty1;
          }
        }
      }
    }
  }
  .form-check-inline:nth-child(2) {
    input[type="radio"] {
      + label {
        background-color: $difficulty2;
      }
      &:hover {
        + label {
          background-color: rgb(235, 185, 42, 0.25);
        }
      }
      &:checked {
        + label {
          background-color: $difficulty2;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty2;
          }
        }
      }
    }
  }

  .form-check-inline:nth-child(3) {
    input[type="radio"] {
      + label {
        background-color: $difficulty3;
      }
      &:hover {
        + label {
          background-color: rgb(251, 150, 38, 0.25);
        }
      }
      &:checked {
        + label {
          background-color: $difficulty3;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty3;
          }
        }
      }
    }
  }

  .form-check-inline:nth-child(4) {
    input[type="radio"] {
      + label {
        background-color: $difficulty4;
      }
      &:hover {
        + label {
          background-color: rgb(251, 102, 32, 0.25);
        }
      }
      &:checked {
        + label {
          background-color: $difficulty4;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty4;
          }
        }
      }
    }
  }

  .form-check-inline:nth-child(5) {
    input[type="radio"] {
      + label {
        background-color: $difficulty5;
      }
      &:hover {
        + label {
          background-color: rgb(235, 18, 24, 0.25);
        }
      }
      &:checked {
        + label {
          background-color: $difficulty5;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty5;
          }
        }
      }
    }
  }
}
.input-difficulty .chosen{
  .form-check-inline {
    .form-check-label {
      font-size: 2rem;
      color: #ccc;
      background-color:$white !important;
      border:none;
    }
  }
  .form-check-inline:nth-child(1) {
    input[type="radio"] {
      &:checked {
        + label {
          background-color: $difficulty1 !important;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty1 !important;
          }
        }
      }
    }
  }
  .form-check-inline:nth-child(2) {
    input[type="radio"] {
      &:checked {
        + label {
          background-color: $difficulty2 !important;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty2 !important;
          }
        }
      }
    }
  }
  .form-check-inline:nth-child(3) {
    input[type="radio"] {
      &:checked {
        + label {
          background-color: $difficulty3 !important;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty3 !important;
          }
        }
      }
    }
  }
  .form-check-inline:nth-child(4) {
    input[type="radio"] {
      &:checked {
        + label {
          background-color: $difficulty4 !important;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty4 !important;
          }
        }
      }
    }
  }
  .form-check-inline:nth-child(5) {
    input[type="radio"] {
      &:checked {
        + label {
          background-color: $difficulty5 !important;
          color: $white;
          border-color: $dark;
        }
        &:hover {
          + label {
            background-color: $difficulty5 !important;
          }
        }
      }
    }
  }
}

.radio-group-bool {
  justify-content: left;
  .form-check {
    margin-right: 1rem;
  }
}
.form-symptoms {
  .radio-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-check {
      padding: 0;
    }
    .form-check-inline {
      margin: 0;
      .form-check-label {
        color: transparent;
        border: 0.125rem solid $white;
        border-radius: 0.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    input[type="radio"] {
      opacity: 0;
      width: 0rem;
      + label {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        background-color: $primary;
        font-size: 1rem;
        width: 3.125rem;
        height: 3.125rem;
        border-radius: $border-radius;
        color: transparent;
      }

      &:hover {
        + label {
          background-color: rgb(6, 94, 184, 0.25);
          color: $black;
        }
      }

      &:checked {
        + label {
          position: relative;
          background-color: inherit;
          color: $white;
        }
        &:hover {
          + label {
            background-color: inherit;
            color: $white;
          }
        }
      }
      &:focus {
        + label {
          box-shadow: 0 0rem 0.5rem $primary;
        }
      }
    }
  }

  // scale colours

  .form-check-inline:nth-child(1) {
    .form-check-label {
      background-color: $difficulty1;
    }
  }
  .form-check-inline:nth-child(2) {
    .form-check-label {
      background-color: $difficulty2;
    }
  }
  .form-check-inline:nth-child(3) {
    .form-check-label {
      background-color: $difficulty3;
    }
  }
  .form-check-inline:nth-child(4) {
    .form-check-label {
      background-color: $difficulty4;
    }
  }
  .form-check-inline:nth-child(5) {
    .form-check-label {
      background-color: $difficulty5;
    }
  }

  .radio-group-sputum {
    .form-check-inline {
      .form-check-label {
        position: relative;
        .sputum-icon {
          position: absolute;
          top: 0rem;
          left: 0.75rem;
          font-size: 2rem;
        }
      }
    }

    .form-check-inline:nth-child(1) {
      .form-check-label {
        background-color: $sputum1;
        position: relative;
      }
    }
    .form-check-inline:nth-child(2) {
      .form-check-label {
        background-color: $sputum2;
      }
    }
    .form-check-inline:nth-child(3) {
      .form-check-label {
        background-color: $sputum3;
      }
    }
    .form-check-inline:nth-child(4) {
      .form-check-label {
        background-color: $sputum4;
      }
    }
    .form-check-inline:nth-child(5) {
      .form-check-label {
        background-color: $difficulty5;
        background-color: $sputum5;
      }
    }
  }

  .chosen {
    .form-check-inline {
      .form-check-label {
        background-color: $white !important;
        position: relative;
      }
      .form-check-label::after {
        content: " ";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.875rem;
        height: 0.875rem;
        margin-top: -0.375rem;
        margin-left: -0.375rem;
        border-radius: 0.1875rem;
      }
    }
    .form-check-inline:nth-child(1) {
      .form-check-label::after {
        background-color: $difficulty1;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $difficulty1 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(2) {
      .form-check-label::after {
        background-color: $difficulty2;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $difficulty2 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(3) {
      .form-check-label::after {
        background-color: $difficulty3;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $difficulty3 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(4) {
      .form-check-label::after {
        background-color: $difficulty4;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $difficulty4 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(5) {
      .form-check-label::after {
        background-color: $difficulty5;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $difficulty5 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
  }
  // sputum...
  .radio-group-sputum.chosen {
    .sputum-icon {
      display: none;
    }
    .form-check-inline:nth-child(1) {
      .form-check-label::after {
        background-color: $sputum1;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $sputum1 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(2) {
      .form-check-label::after {
        background-color: $sputum2;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $sputum2 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(3) {
      .form-check-label::after {
        background-color: $sputum3;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $sputum3 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(4) {
      .form-check-label::after {
        background-color: $sputum4;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $sputum4 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
    .form-check-inline:nth-child(5) {
      .form-check-label::after {
        background-color: $sputum5;
      }
      input[type="radio"] {
        &:checked {
          + label {
            position: relative;
            background-color: $sputum5 !important;
            color: $white;
          }
          + label.form-check-label::after {
            display: none !important;
          }
        }
      }
    }
  }
  .form-group-wellbeing {
    background-color: $primary;
    color: $white;

    .scale {
      .line {
        border-bottom: 0.125rem solid rgba($white, 0.25);
      }
    }

    .arrow-right {
      border-bottom: 0.125rem solid $white;
    }
    .arrow-right::after {
      border-left: 0.4375rem solid $white;
    }

    .arrow-left {
      border-bottom: 0.125rem solid $white;
    }
    .arrow-left::after {
      border-right: 0.4375rem solid $white;
    }
  }
}

// arrows for scales
.normal-indicator-container {
  display: flex;
  justify-content: space-between;
  > div {
    min-width: 3.125rem;
    text-align: center;
  }
}
.scale {
  display: flex;
  justify-content: space-between;
  .line {
    border-bottom: 0.125rem solid rgba(#333, 0.25);
    display: block;
    min-width: 3.125rem;
    margin: 0.625rem auto;
  }
}
.scale > * {
}

.arrow-right {
  display: block;
  position: relative;
  margin: 0.625rem 0;
  height: 0;
  border-bottom: 0.125rem solid $dark;
  min-width: 3.125rem;
}
.arrow-right::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0.25rem solid transparent;
  border-bottom: 0.25rem solid transparent;
  border-left: 0.4375rem solid $dark;
  position: absolute;
  right: -0.125rem;
  top: -0.1875rem;
}

.arrow-left {
  display: block;
  position: relative;
  margin: 0.625rem 0;
  height: 0;
  border-bottom: 0.125rem solid $dark;
  min-width: 3.125rem;
}
.arrow-left::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0.25rem solid transparent;
  border-bottom: 0.25rem solid transparent;
  border-right: 0.4375rem solid $dark;
  position: absolute;
  left: -0.125rem;
  top: -0.1875rem;
}



h2.form-subheading{
  font-size:1.25rem;
  margin: 1rem 0;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  .form-subheading-aside{
    font-size:0.875rem;
    text-transform: none;
    font-weight: 400;
    color:#666;

  }
}