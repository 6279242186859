.navbar-main {
  min-height: 60px;
  position: relative;
  z-index: 10;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 75%
  ) !important;

  .back-link {
    position: absolute;
    top: 12px;
    left: 10px;
    color: $primary;
    font-size: 1.125rem;
    line-height: 1.125rem;
    display: inline;
    padding: 0.5rem 1rem 0.5rem 0.9rem;
    border-radius: $border-radius;
    &:hover {
      background-color: rgb(255, 255, 255, 0.1);
    }
    &:active {
      background-color: rgb(255, 255, 255, 0.3) !important;
      transition: 0s;
    }
  }

  .navbar-brand {
    font-family: $headings-font-family;
    font-weight: normal;
    display: inline-block;
    top: 0px;
    padding: 0;
    text-align: center;
    img {
      height: 45px;
      margin-top: 0px;
    }
  }
  h1 {
    color: $primary;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 60px;
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;
  }
  .btn-nav-menu {
    font-size: 1rem;
    width: 2rem;
    display: inline-block;
    line-height: 2rem;
    text-align: center;
    height: 2rem;
    color: $white;
    border: none;
    background-color: $secondary-accent;
    border-radius: 50%;
    position: relative;
    top: 6px;
  }
}

// media queries
@include media-breakpoint-up(md) {
  .navbar-main {
    display:none;
    background: $primary !important;
    color: $white;
    margin-top: 1rem;
    border-radius: $border-radius;
   
    h1 {
      color: $white;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar-main {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
  }
  .navbar-main {
    .back-link {
      left: 2px;
    }
  }
}

.aside-menu-wrap {
  .navbar-main {
    position: relative;
    background: $primary !important;
    h1 {
      color: $white;
    }
    .btn-nav-menu {
      top:0;
    }
  }
}


.has-footer-nav .navbar-main .btn-nav-menu {
  line-height: 1rem;
}