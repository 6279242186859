.pick-list{
    margin:0;
    padding:0;
     >li{
        list-style: none;
        padding: .75rem 1.5rem .75rem .75rem;
        color:$dark;
        cursor: pointer;
        margin-bottom: .75rem;
        border-radius: $border-radius;
        background-color: $light;
        position: relative;
        .pick-icon{
            margin-right:1rem;
        }
        .expand-icon{
            position: absolute;
            right:.75rem;
            font-size:1rem;
        }
        ul{
            margin:.5rem 0 0 2rem;
            padding:0;
            font-size:.75rem;
            li{
                list-style: none;
                padding:.5rem;
            }
        }
    }
    li.open{
        .expand-icon, .pick-item{
            color:$dark;
        }
    }
    li.active{
        background-color: $dark;
        color:$white;
    }
}