.achievement-notification{
    position: fixed;
    width:100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index:99999;

    -webkit-animation: reveal-award 0.4s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
	animation: reveal-award 0.4s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;

    .back-link{
        color:$white;
        background-color: $secondary-accent;
        border-radius: 50%;
        width:1.875rem;
        height:1.875rem;
        min-width: 1.875rem;
        min-height: 1.875rem;
        display:inline-block;
        line-height: 1.875rem;
        text-align: center;
        font-size:1rem;
        margin-right:.6rem;
        position: absolute;
        top:1.25rem;
        left:1.25rem;

    }
    .achievement-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color:$white;
        text-align: center;
        margin:10% auto;
        max-width: 25rem;
        padding:2.5rem;
        h1{ font-size:1.75rem;}
        h2{ font-size:1.125rem;
        }
        h3{ font-size:0.875rem;}
        .achievement{
            width:12.5rem;
            height: 12.5rem;
            margin: 2rem auto 1rem auto;
            .achievement-bg{
                line-height: 10rem;
                font-size: 10rem;
                top:0.625rem;
            }
            .achievement-value{
                font-size:3.75rem;
                margin-right: 0.5rem;
            }
            .achievement-label{
                font-size: 1.5rem;
                line-height: 1rem;
                margin-top: 0.5rem;
            }
            .achievement-icon{
                width:3.75rem;
            }
        }
    }
}

.bg-light{
    .achievement-wrap{
        color:$dark;
    }
}