html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}

.fh {
  min-height: 100vh;
}

.fh-nav {
  min-height: calc(100vh - 60px);
}

.fh-nav-subnav {
  min-height: calc(100vh - 151px);
}

.fh-nav-subnav-stagenav {
  min-height: calc(100vh - 197px);
}

.content-container {
  padding: 1.25rem;
  .row {
    margin-top: 1rem;
  }
  p {
    font-size: 0.875rem;
  }
}

.container-narrow {
  max-width: 780px;
  margin: 0 auto;
}

.bg-dark {
  background: $dark;
  color: $white;
  a {
    color: $white;
  }
  hr {
    border-color: rgba($white, 0.5);
  }
}

.bg-grey {
  background-color: #ddd;
}

.bg-menu-bg {
  background-color: $dark;
}

.text-menu-bg {
  color: $dark;
}

.text-light-grey {
  color: #ddd;
}

.divider {
  border-top-width: 0.0625rem;
}

.divider-light {
  border-color: rgba(255, 255, 255, 0.5);
}

.drop-shadow {
  box-shadow: 0.4375rem 0.4375rem 0rem 0rem rgba(0, 0, 0, 0.1);
}

a .drop-shadow:active {
  box-shadow: 0.1875rem 0.1875rem 0rem 0prem rgba(0, 0, 0, 0.2);
  transform: translate(0.1875rem, 0.1875rem);
}

.pointer {
  cursor: pointer;
}

// extra bgs
@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: rgba($value, 1) !important;
  }
}

@each $color, $value in $theme-colors {
  .bg-#{$color}-dark {
    background-color: rgba(darken($value, 14), 1);
  }
}

@each $color, $value in $theme-colors {
  .bg-#{$color}-darker {
    background-color: rgba(darken($value, 24), 1);
  }
}

// form addons
.inner-addon {
  position: relative;
}

.inner-addon .addon {
  position: absolute;
  padding: 0.625rem;
  z-index: 20;
}

.inner-addon .addon.btn {
  padding: 0.3125rem;
  margin: 0.3125rem;
  text-align: center;
}

.left-addon .addon {
  left: 0rem;
}

.right-addon .addon {
  right: 0rem;
}

.left-addon input {
  padding-left: 1.875rem;
}

.right-addon input {
  padding-right: 1.875rem;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.nmh {
  min-height: auto !important;
}

.icon-xl {
  font-size: 3rem;
}

.icon-button {
  margin-top: -0.2rem;
  padding: 0.2rem 0.5rem;
  display: inline-block;
  border-radius: $border-radius;
  transition: 0.3s;

  &:hover {
    background-color: rgb(255, 255, 255, 0.1);
  }

  &:active {
    background-color: rgb(255, 255, 255, 0.2);
  }
}

.cc-window {
  background-color: $dark !important;
  font-size: 0.875rem !important;
  font-family: "Frutiger" !important;
  box-shadow: inset 0rem 0.125rem 0.3125rem 0rem rgba(0, 0, 0, 0.25);
}

.cc-btn {
  border-radius: $border-radius !important;
  color: $primary !important;
  font-weight: 300 !important;

  &:hover {
    background-color: #ececec !important;
  }
}
.cc-revoke {
  display: none !important;
}

// media queries
@include media-breakpoint-up(md) {
  .content-wrap {
    margin: 1rem auto;
    background-color: $white;
    border-radius: $border-radius;
    min-height: 100vh;
    padding: 0.5rem 1.5rem;
  }

  .content-wrap-np {
    padding: 0;
  }

  router-outlet ~ * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

// content box
.content-container {
  padding: 1rem;
  padding-bottom: 6.25rem;
}

.card.bg-primary {
  color: $white;
}

.content-box {
  position: relative;
  z-index: 1;
  border-radius: $border-radius-lg $border-radius-lg 0 0;
  background-color: rgba($primary, 0.9);
  padding: 1rem;
  margin-top: 33vh;
  color: $white;

  h1 {
    margin-bottom: 2rem;
    font-size: 1.5rem;

    small {
      font-size: 0.625rem;
      font-family: $font-family-sans-serif;
      text-transform: none;
      line-height: 2rem;
      font-weight: 500;
      color: $primary-accent;
    }
  }
}

.inline-title {
  text-align: left;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: $primary;

  .muted {
    font-size: 0.625rem;
    line-height: 1.2rem;
  }
}

.card {
  color: $dark;
  border-radius: $border-radius-lg;

  .card-title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 1rem;

    .icon-wrap {
      margin-right: 0.3125rem;
    }

    .icon-circle {
      background-color: $white;
      border-radius: 50%;
      width: 1.875rem;
      height: 1.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      position: relative;
      top: -0.3125rem;
    }
  }

  .card-title-sm {
    font-size: 0.625rem;
    font-weight: bold;
  }

  .card-footer {
    border-radius: 0 0 $border-radius-lg $border-radius-lg;

    a {
      text-decoration: none;
      color: $primary;
      font-weight: 700;
      font-family: $headings-font-family;
      text-align: center;
      display: block;
      font-size: 0.875rem;
    }
  }

  .divider {
    background-color: $white;
    height: 0.125rem;
    opacity: 1;
  }

  .card-text-sm {
    font-size: 0.625rem;
    margin-bottom: 0rem;
  }
}

.card.bg-white p {
  color: #666;
}

.card.widget {
  .card-body {
    padding: 1rem 0.5rem;
  }
}
.card-body {
  p {
    font-size: 0.875rem;
  }

  p.subtext {
    color: $gray-666;
    font-size: 0.625rem;
  }
}

.img-sticky {
  position: fixed;
  z-index: 0;
  width: 100%;
  top: 0;
  left: 0;
}

h1 {
  text-transform: uppercase;

  .icon-wrap {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    margin-right: 0.5625rem;

    svg {
      position: relative;
      top: -0.25rem;
      fill: $secondary-accent;
      width: 100%;
      height: auto;
    }
  }
}

.widget-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: stretch;

  .icon-wrap {
    width: 4rem;
    max-height: 4rem;
    display: inline-block;
    flex: 1;

    svg {
      fill: $primary;
      width: auto;
      height: 4rem;
    }
  }

  .icon-symptoms {
    width: 3.25rem;
    max-height: 3.25rem;
    display: inline-block;
    flex: 1;
    margin: 0.5rem 0 0 0.4375rem;

    svg {
      fill: $primary;
      width: auto;
      height: 3.25rem;
    }
  }

  .icon-yoga {
    width: 5rem;
    max-height: 5rem;
    display: inline-block;
    flex: 1;
   // margin: 0.1875rem 0 0.3125rem 0rem;
    margin: -0.5rem 0 0rem -0.6rem;

    svg {
      fill: $primary;
      width: auto;
      height: 5rem;
    }
  }

  .widget-status {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: stretch;

    .widget-label {
      border-radius: 0.625rem;
      background-color: $white;
      margin: 0.125rem 0rem 0.4375rem 0.625rem;
      flex: 1;
      padding: 0.125rem 0.3125rem;
      display: flex;
      justify-content: left;
      align-items: center;
      flex-direction: row;
      align-items: stretch;
      min-width: 4.375rem;

      .text-dark {
        color: $primary !important;
      }

      span {
        margin: 0 0.3125rem;
      }

      .not-hit {
        font-size: 2rem;
        line-height: 1rem;
        text-align: center;
      }

      .not-set {
        font-size: 0.625rem;
        line-height: 0.625rem;
        margin-top: 0.15rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}

// MEDIA QUERIES

@include media-breakpoint-up(sm) {
  .content-box {
    margin-top: 0rem;
    border-radius: 0;
  }
}

@include media-breakpoint-up(lg) {
  .content-box {
    margin-top: 2rem;
    border-radius: 1.25rem 1.25rem 0 0;
  }
}

// entries

.entry-days {
  display: flex;
  justify-content: center;
  align-items: center;

  .entry-day {
    text-align: center;
    margin-right: 0.5rem;
    flex: 1;

    .entry-day-label {
      font-size: 0.875rem;
      font-weight: 700;
    }

    .entry-day-data {
      border-radius: 0.5rem;
      height: 2.375rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: $white;
      color: $primary;

      .entry-day-data-value {
        font-size: 1.0625rem;
        font-weight: 700;
        line-height: 1rem;
      }

      .entry-day-data-unit {
        font-size: 0.6875rem;
        line-height: 0.6875rem;
      }
    }

    .entry-day-data.complete {
      background-color: $primary;
      color: $white;
    }

    .entry-day-data.na {
      background-color: rgba($white, 0.5);
      color: $primary;
    }
  }
}

.goal-status-row {
  margin: 0;

  .col {
    padding: 0.5rem;
  }

  .goal-status-value {
    font-size: 1.5rem;
    color: $primary;
    font-family: $headings-font-family;
    font-weight: 800;
  }

  .col:first-child {
    border-top: 0.125rem solid #eee;
    border-right: 0.0625rem solid #eee;
  }

  .col:last-child {
    border-top: 0.125rem solid #eee;
    border-left: 0.0625rem solid #eee;
  }
}
// end entries

// achievements
.achievements {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  clear: both;
  flex-wrap: wrap;
}

.achievements {
  .achievement {
    margin-bottom: 1rem;
  }
}

.achievement {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $white;
  border-radius: 0.625rem;
  width: 3.75rem;
  height: 3.75rem;
  position: relative;
  margin: 0 0.5rem;

  .achievement-bg {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 3.75rem;
    width: 100%;
    height: 100%;
    z-index: 0;
    color: #eee;
    text-align: center;
    font-size: 3rem;
  }

  .achievement-content {
    text-align: center;
    position: relative;
    z-index: 1;
    font-size: 0.5rem;
    font-family: $headings-font-family;
    color: $primary;
  }

  .achievement-value {
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 1.125rem;
  }

  .achievement-label {
    text-transform: uppercase;
    white-space: pre;
    line-height: 1;
    margin-top: 0.25rem;

    span {
      line-height: 1;
    }
  }

  .achievement-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .achievement-icon {
    width: 1.25rem;
    display: inline-block;

    svg {
      width: 100%;
      height: auto;
      fill: $secondary-accent;
    }

    .icon-recharge,
    .icon-strength,
    .icon-yoga {
    }
  }
}

.achievement-primary {
  background-color: $primary;
  color: $white;

  .achievement-content {
    color: $white;
  }

  .achievement-icon {
    margin-right: 0.25rem;

    svg {
      fill: $white;
      fill: $white;
    }
  }
}
// end achievements

// media

@include media-breakpoint-down(md) {
  /*.achievements {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }*/
}

.goal-content {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  padding: 0.5rem 0;

  .goal-checkbox {
    min-width: 1.875rem;
    width: 1.875rem;
    height: 1.875rem;
    background-color: $white;
    border-radius: 0.625rem;
    display: inline-block;
    text-align: center;
    line-height: 1.875rem;
    margin-right: 0.625rem;
    color: #efefef;
  }
}

.target-status {
  align-items: center;
  margin-bottom: 1rem;
}

.target-status-status {
  font-size: 0.875rem;

  .icon-wrap {
    font-size: 1rem;
  }

  color: $primary;
}

.target-status-date {
  font-size: 0.625rem;
}

.status-calendar {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  margin: 1rem 0;

  .status-calendar-header {
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 0.875rem;
  }

  .status-calendar-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0.125rem 0.125rem;
    gap: 0.125rem 0.125rem;
    margin-bottom: 0.125rem;
    text-align: center;

    .status-calendar-day {
      display: inline-block;
      width: 1.3125rem;
      height: 1.3125rem;
      border-radius: 50%;
      font-size: 0.625rem;
      text-align: center;
      line-height: 1.3125rem;
    }

    .status-calendar-day.incomplete {
      background-color: $white;
    }

    .status-calendar-day.complete {
      background-color: $primary;
      color: $white;
    }
  }
}

.status-badge {
  background-color: $white;
  border-radius: 0.625rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .status-badge-icon {
    color: $secondary-accent;
    font-size: 1.5rem;
    margin-right: 0.625rem;
  }

  .status-badge-text {
    text-align: center;

    .status-badge-title {
      color: $gray-666;
      font-size: 0.625rem;
    }

    .status-badge-status {
      font-size: 1.063rem;
      font-weight: 700;
      color: $primary;

      span {
        font-size: 0.6875rem;
        font-weight: 400;
      }
    }
  }
}

// swiper test

.swiper {
  width: 100%;
  padding-bottom: 1.875rem;
}

.swiper-slide {
  text-align: left;
  font-size: 0.875rem;
}

.swiper-pagination-bullet {
  background-color: $secondary-accent;
  opacity: 1;
  width: 0.625rem;
  height: 0.625rem;
}

.swiper-pagination-bullet-active {
  background-color: $primary;
}

.swiper-pagination {
  bottom: 0rem !important;
}

// symptom-status

.symptom-status {
  margin-bottom: 0.625rem;

  .symptom-status-label {
    font-size: 0.625rem;
    font-family: $headings-font-family;
    font-weight: 800;
    color: $primary;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0.0625rem;
  }

  .symptom-status-box {
    background-color: $white;
    border-radius: 0.625rem;
    padding: 0.3125rem 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .symptom-status-icon {
      font-size: 1rem;
      margin-right: 0.125rem;
    }

    .symptom-status-value {
      font-size: 1.5rem;
      font-family: $headings-font-family;
      font-weight: 800;
      margin-left: 0.125rem;
    }

    .icon-selection {
      border-radius: 0.3125rem;
      width: 1.75rem;
      height: 1.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.3125rem;
      color: $white;
    }
  }
}

.symptom-status-inline {
  display: inline-block;

  .symptom-status-icon {
    margin-right: 0.3125rem;
  }

  .symptom-status-icon {
    margin-right: 0.3125rem;
  }
}

h3.sm-title,
h2.sm-title {
  font-size: 1.5rem;
}

.card .card-title .float-end .icon-wrap {
  margin-right: 0rem;
}

.content-box h1 .icon-heading-walking,
.content-box h1 .icon-heading-strength {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.3125rem;
}

.content-box h1 .icon-heading-managing,
.content-box h1 .icon-heading-yoga {
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.375rem;
}

@include media-breakpoint-down(md) {
  .bg-section {
    background-color: $primary;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
  }
}

[hidden] {
  display: none !important;
}

.resource-link-container{
  display: flex;
  flex-wrap: wrap;
.resource-link {
  padding:1rem;
  flex: 50%;
  max-width: 50%;
  position: relative;
  background: none;
  > a {
    display:block;
    position: relative;
    background: $primary;
    border-radius: 1.25rem;
  }
  img{
    display: block;
  }
  .resource-link-gradient {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.7) 17%,
      rgba(0, 0, 0, 0) 33%
    );
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 1.25rem;
  }
  .resource-link-title {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 0.875rem 1.25rem ;
    width: 100%;
    color: $white;
    text-transform: uppercase;
    font-size: 1rem;
    .icon-wrap {
      position: absolute;
      right: 1.25rem;
    }
  }
}
}

.read-content {
  font-size: 0.875rem;
  p {
    font-size: 0.875rem;
  }

  ul, ol {
    font-size: 0.875rem;
    padding-left: 1rem;
    li {
      margin-bottom: 1rem;
    }
  }
  h3 {
    font-size: 1.125rem;
    color: $primary;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    padding-top: 1.5rem;
    border-top: 0.125rem solid rgba(0, 0, 0, 0.05);
  }

  h4 {
    font-size: 0.875rem;
    color: $primary;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 2.5rem;
  }

  h4:first-of-type {
    margin-top: 1.5rem;
  }
}

.more-box {
  margin-top: 1rem;
}


@include media-breakpoint-down(md) {
  .resource-link-container{.resource-link {
    flex: 100%;
    max-width: 100%;
  }}
}

.feedback-link{
  display: inline-block;
  color:$white;
  text-decoration: none;
  margin-top: .5rem;
  font-size:0.875rem;
}

.feedback-link:hover{
  color:$white;
  cursor: pointer;

}
.feedback-container{
  padding-left:15px;
  padding-top:8px;
}