.task-list {
  list-style: none;
  margin: 0;
  padding: 0;
  color: $dark;
  font-size: 0.875rem;
  li > a {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding-bottom: 0.625rem;
    transition: 0.3s;
    text-decoration: none;
  }
  li > .flex-wrap {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    flex-flow: row nowrap !important;
    justify-content: flex-start;
    padding-bottom: 0.625rem;
  }
  li > a:hover {
    // background-color: $light;
    text-decoration: none;
  }
  .num {
   // background-color: $light;
    margin-right: 0.3125rem;
    padding: 0.4375rem 0.75rem;
    min-width: 2.8125rem;
    max-width: 2.8125rem;
    text-align: center;
    border-radius: $border-radius;
    font-size: 1.5rem;
    .icon-wrap {
      font-size: 1.125rem;
      svg {
        fill: $primary;
      }
    }
  }
  .task {
    color: $dark;
    //background-color: $light;
    border-radius: $border-radius;
    padding: 0.3125rem 0rem;
    flex-grow: 1;
    display: flex;
    position: relative;

    align-items: center;
    text-decoration: none;
    .date{
      min-width:5.625rem;
      font-size: 0.75rem;
    }
    .label {
      text-transform: uppercase;
      min-width: 1.5rem;
      display: inline-block;
    }
    .title {
      padding-right: 1.25rem;
      font-size: 0.75rem;
      .small {
        display: block;
        margin-top: -0.1875rem;
        font-size: 0.625rem;
      }
    }
    .status {
      position: absolute;
      right: 0.625rem;
      color: $primary;
    }
  }
  li.current {
    .num,
    .task {
    }
  }
  li.complete {
    .task {
    }
  }
}
.task-list-bt{
  border-top: 0.125rem solid rgba(0, 0, 0, 0.05);
}

.task-list {
  transition: 0.3s;
}

.task-list-todo {
  .completed {
    color: $gray-999;
    .icon-wrap {
      .app-icon svg {
        fill: $gray-999;
      }
    }
  }
  li {
    border-bottom: 0.125rem solid rgba(0, 0, 0, 0.05);
  }
  .num {
    min-width: 1.875rem;
    max-width: 1.875rem;
    padding: 0rem;
    margin-top: -0.25rem;
    .icon-wrap {
      font-size: 1.125rem;
      display: inline-block;
      .app-icon svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-top: -0.5rem;
      }
    }
    .icon-walking {
      svg {
        height: 2rem;
      }
    }
  }
}

h3.month-header{
  font-size:1rem;
  margin:1rem 0;
}